import _createForOfIteratorHelper from "/var/www/git/antares-web-frontend/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _objectSpread from "/var/www/git/antares-web-frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _slicedToArray from "/var/www/git/antares-web-frontend/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.entries.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-63d3c0f7"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["onTouchstart"];
var _hoisted_2 = ["onMouseenter"];
var _hoisted_3 = ["href"];
var _hoisted_4 = ["onMouseenter"];
var _hoisted_5 = {
  key: 2
};
var _hoisted_6 = ["onMouseenter"];
var _hoisted_7 = {
  class: "scroll-wrapper"
};
var _hoisted_8 = ["data-key"];
import { ref, reactive, computed, onMounted, onBeforeUnmount, nextTick, watch } from 'vue';
import { refThrottled } from "@vueuse/core";
import { getSize, registerResize, removeResize } from '@/composables/windowWatcher';
import ProductIcon from '@/components/ProductComponents/ProductIcon.vue';
import { ArrowLeftBold, ArrowRightBold } from '@element-plus/icons-vue';
import NavMenu from "./NavMenu.json";
export default {
  setup: function setup(__props) {
    var navBarMenu = ref(null);
    var hasEL = ref(false);
    var currMenu = ref(-1);
    var menuEntryElems = ref({});
    var menuEntrySizes = reactive({});
    var subMenus = ref([]);
    var subMenuSizes = reactive({});
    var subMenuScrolls = ref([]);
    var currMenuThrottled = refThrottled(currMenu, 100);
    var windowSize = getSize();
    var itemWidth = 120; // submenu entry width; defined here to sync scroll handler to always scroll 3 items

    var copy = JSON.parse(JSON.stringify(NavMenu));
    var menuItems = ref(copy.filter(function (item) {
      if (item.submenu) {
        item.submenu = item.submenu.filter(function (subitem) {
          return !subitem.hideFromNavBar;
        });
      }

      return !item.hideFromNavBar;
    })); // Computed

    var currMenuElem = computed(function () {
      if (!subMenus.value) {
        return null;
      }

      var items = Object.values(subMenus.value);

      if (!(items && items.length)) {
        return null;
      }

      return items.find(function (elem) {
        return elem.getAttribute("data-key") === currMenu.value;
      });
    });
    /**
     * Calculate Menu Offsets for margin calculation
     * Offsets are in relation to center of screen width
     * 
     * max (0px, half of container - (half content width +/- offset from center) +/- overflow of opposing side, min 0 )
     * 
     * Margin calculation will attempt to place the center of the submenu content on the same horizontal value of the center point of its associated main menu entry.
     * If this would cause a portion of the submenu to overflow out of screen, this will attempt to adjust the margin on the opposed side to keep content on screen.
     * If neither margin can be adjusted to accomodate the submenu size, a horizontal scrollable submenu will be present.
     */

    var subMenuOffsetsComp = computed(function () {
      var offsets = [];

      if (!(menuEntryElems.value && Object.values(menuEntryElems.value).length)) {
        return offsets;
      }

      for (var _i = 0, _Object$entries = Object.entries(menuEntryElems.value); _i < _Object$entries.length; _i++) {
        var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
            key = _Object$entries$_i[0],
            el = _Object$entries$_i[1];

        var rect = menuEntrySizes[key] || {
          left: 0,
          width: 0
        };
        offsets[key] = windowSize.width / 2 - (rect.left + rect.width / 2);
      }

      return offsets;
    });
    var showLeftArrow = computed(function () {
      if (currMenu.value < 0) {
        return false;
      }

      var dimensions = subMenuSizes[currMenu.value];

      if (!dimensions || dimensions.width === 0 || dimensions.scrollWidth <= dimensions.width) {
        return false;
      }

      return subMenuScrolls.value[currMenu.value] > 0;
    });
    var showRightArrow = computed(function () {
      if (currMenu.value < 0) {
        return false;
      }

      var dimensions = subMenuSizes[currMenu.value];

      if (!dimensions || dimensions.width === 0 || dimensions.scrollWidth <= dimensions.width) {
        return false;
      }

      return subMenuScrolls.value[currMenu.value] < dimensions.scrollWidth - dimensions.width;
    }); // Methods

    /**
     * Build out the Destination object for vue-router's router-link component
     * 
     * @param {Object} item - main menu object entry
     * @param {Object} subitem - sub menu object entry
     * @returns {RouteLocationRaw} destination
     */

    var buildDestinationObj = function buildDestinationObj(item, subitem) {
      if (subitem.externalLink) {
        // Skip; submenu item points externally
        return null;
      }

      if (subitem.destination) {
        return subitem.destination;
      }

      if (!item.destination) {
        return null;
      }

      return _objectSpread(_objectSpread({}, item.destination), {}, {
        params: {
          slug: subitem.slug
        }
      });
    };
    /**
     * Clean the specified submenu.
     * Remove event listener & reset scroll values
     * 
     * @param {HTMLElement} elem 
     */


    var resetRow = function resetRow(elem) {
      if (!elem) {
        return;
      }

      var index = elem.getAttribute("data-key");
      elem.removeEventListener("scroll", onScroll);
      subMenuScrolls.value[index] = 0;
      setTimeout(function () {
        elem.scrollTo(0, 0);
      }, 300); // Timer should match animation time
    };
    /**
     * Scrollhandler function for EventListener.
     * Update
     * 
     * @param {Object} event 
     */


    var onScroll = function onScroll(event) {
      subMenuScrolls.value[currMenu.value] = event.target.scrollLeft;
    };
    /**
     * Open Submenu corresponding to provided Key
     * 
     * @param {String} i 
     */


    var handleDropdown = function handleDropdown(i) {
      if (currMenu.value !== -1 && subMenus.value[i] && subMenus.value[i] === currMenuElem.value) {
        return;
      }

      var prevMenuElem = currMenuElem.value;
      subMenuScrolls.value[i] = 0;
      currMenu.value = i;

      if (currMenuElem.value) {
        currMenuElem.value.addEventListener("scroll", onScroll);
      }

      if (prevMenuElem) {
        resetRow(prevMenuElem);
      }
    };
    /**
     * Event handler for clearing submenu dropdown when user touches section outside of this component.
     * 
     * Allows for submenu dropdown to be closed on touch screen devices.
     * 
     * @param {TouchEvent} e 
     */


    var handleTouchOut = function handleTouchOut(e) {
      var _navBarMenu$value;

      if (!((_navBarMenu$value = navBarMenu.value) !== null && _navBarMenu$value !== void 0 && _navBarMenu$value.$el.contains(e.target)) && currMenu.value !== -1) {
        clearDropdown();
        document.removeEventListener('touchstart', handleTouchOut);
        hasEL.value = false;
      }
    };
    /**
     * Handles a touchstart event depending on the current state.
     * 
     * If the index has an associated submenu, open the submenu on first touch.
     * If the submenu is already opened, simulate a click on the target element.
     * 
     * If no submenu exists for the index, simply simulate a click on the target element.
     * 
     * @param {TouchEvent} e 
     * @param {String} index
     */


    var handleTouchStart = function handleTouchStart(e, index) {
      if (e.touches.length > 1) {
        return;
      } // No submenu exists for the new index or submenu is already opened


      if (!subMenus.value[index] || index === currMenu.value) {
        var _e$target;

        (_e$target = e.target) === null || _e$target === void 0 ? void 0 : _e$target.click();
        clearDropdown();
        return;
      } // Insert a global event listener if one is not mounted


      if (!hasEL.value) {
        hasEL.value = true;
        document.addEventListener('touchstart', handleTouchOut, {
          passive: true
        });
      }

      handleDropdown(index);
    };
    /**
     * Open new Submenu
     * 
     * @param {Number} i 
     */


    var onDropdown = function onDropdown(_ref) {
      var e = _ref.e,
          i = _ref.i;

      switch (e.type) {
        case "touchstart":
          if (e.cancelable) {
            e.stopImmediatePropagation();
            e.preventDefault();
          }

          handleTouchStart(e, i);
          break;

        case "mouseenter":
          handleDropdown(i);
          break;
      }
    };
    /**
     * Close the current active submenu
     */


    var clearDropdown = function clearDropdown() {
      var prevMenuElem = currMenuElem.value;
      currMenu.value = -1;
      resetRow(prevMenuElem);
    };
    /**
     * Programatically scroll the current submenu instance
     * 
     * @param {Number} val value to horizontally scroll by
     */


    var scrollSubmenu = function scrollSubmenu(val) {
      if (currMenuElem.value) {
        currMenuElem.value.scrollBy(val, 0);
      }
    };
    /**
     * Handles clicking on a submenu item. If submenu item's parent object contains a property 'track', then
     * then an event object is 'pushed' into the GA dataLayer object.
     * @param {object} item
     * @param {object} subMenu
     */


    var handleSubmenuClick = function handleSubmenuClick(item, subMenu) {
      if (subMenu.track) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'view_item',
          ecommerce: {
            items: [{
              item_name: item.name
            }]
          }
        });
      }
    }; // Hooks


    watch(windowSize, function () {
      nextTick(function () {
        for (var _i2 = 0, _Object$entries2 = Object.entries(menuEntryElems.value); _i2 < _Object$entries2.length; _i2++) {
          var _Object$entries2$_i = _slicedToArray(_Object$entries2[_i2], 2),
              key = _Object$entries2$_i[0],
              el = _Object$entries2$_i[1];

          if (!el) {
            continue;
          }

          var rect = el.getBoundingClientRect();
          var data = {
            left: rect.left,
            width: rect.width
          }; // Browser Compat to ensure we get a width

          if (typeof data.width !== "number") {
            data.width = el.clientWidth;
          }

          menuEntrySizes[key] = data;
        }
      });
    });
    onMounted(function () {
      nextTick(function () {
        for (var _i3 = 0, _Object$entries3 = Object.entries(menuEntryElems.value); _i3 < _Object$entries3.length; _i3++) {
          var _Object$entries3$_i = _slicedToArray(_Object$entries3[_i3], 2),
              key = _Object$entries3$_i[0],
              el = _Object$entries3$_i[1];

          if (!el) {
            console.warn("Empty element encountered");
            continue;
          }

          var rect = el.getBoundingClientRect();
          var data = {
            left: rect.left,
            width: rect.width
          }; // Browser Compat to ensure we get a width

          if (typeof data.width !== "number") {
            data.width = el.clientWidth;
          }

          menuEntrySizes[key] = data;
        }

        for (var _i4 = 0, _Object$values = Object.values(subMenus.value); _i4 < _Object$values.length; _i4++) {
          var entry = _Object$values[_i4];

          if (!entry) {
            console.warn("Empty element encountered");
            continue;
          }

          var index = entry.getAttribute('data-key');
          subMenuSizes[index] = registerResize(entry, ["width", "scrollWidth"]);
        }
      });
    });
    onBeforeUnmount(function () {
      var _iterator = _createForOfIteratorHelper(subMenus.value),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var entry = _step.value;
          removeResize(entry);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      if (hasEL.value) {
        document.removeEventListener('touchstart', handleTouchOut);
      }
    });
    return function (_ctx, _cache) {
      var _component_router_link = _resolveComponent("router-link");

      var _component_el_icon = _resolveComponent("el-icon");

      var _component_el_row = _resolveComponent("el-row");

      return _openBlock(), _createBlock(_component_el_row, {
        justify: "center",
        style: {
          "flex-wrap": "nowrap",
          "height": "100%"
        },
        onMouseleave: clearDropdown,
        ref_key: "navBarMenu",
        ref: navBarMenu
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuItems.value, function (item, i) {
            return _openBlock(), _createElementBlock("div", {
              key: i
            }, [_createElementVNode("div", {
              class: _normalizeClass(["fill-container flex-col flex-centered menu-item", {
                active: _unref(currMenuThrottled) === "submenu_".concat(i)
              }]),
              ref_for: true,
              ref: function ref(el) {
                return menuEntryElems.value["submenu_".concat(i)] = el;
              },
              onTouchstart: function onTouchstart(e) {
                return onDropdown({
                  e: e,
                  i: "submenu_".concat(i)
                });
              }
            }, [item.destination ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: item.destination
            }, {
              default: _withCtx(function () {
                return [_createElementVNode("h3", {
                  class: "menu-label",
                  onMouseenter: function onMouseenter(e) {
                    return onDropdown({
                      e: e,
                      i: "submenu_".concat(i)
                    });
                  }
                }, _toDisplayString(item.name), 41, _hoisted_2)];
              }),
              _: 2
            }, 1032, ["to"])) : item.externalLink ? (_openBlock(), _createElementBlock("a", {
              key: 1,
              href: item.externalLink,
              target: "_blank"
            }, [_createElementVNode("h3", {
              class: "menu-label",
              onMouseenter: function onMouseenter(e) {
                return onDropdown({
                  e: e,
                  i: "submenu_".concat(i)
                });
              }
            }, _toDisplayString(item.name), 41, _hoisted_4)], 8, _hoisted_3)) : (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("h3", {
              class: "menu-label",
              onMouseenter: function onMouseenter(e) {
                return onDropdown({
                  e: e,
                  i: "submenu_".concat(i)
                });
              }
            }, _toDisplayString(item.name), 41, _hoisted_6)]))], 42, _hoisted_1), item.submenu && item.submenu.length > 0 ? (_openBlock(), _createBlock(_Transition, {
              key: 0,
              appear: "",
              name: "submenu"
            }, {
              default: _withCtx(function () {
                return [_withDirectives(_createVNode(_component_el_row, {
                  class: "submenu"
                }, {
                  default: _withCtx(function () {
                    return [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", {
                      class: _normalizeClass(["flex-row scroll-container", {
                        active: _unref(currMenuThrottled) === "submenu_".concat(i)
                      }]),
                      "data-key": "submenu_".concat(i),
                      ref_for: true,
                      ref: function ref(el) {
                        return subMenus.value["submenu_".concat(i)] = el;
                      }
                    }, [_createElementVNode("div", {
                      class: "content",
                      style: _normalizeStyle({
                        'margin-left': "max(0px, calc(50% - ".concat(itemWidth * item.submenu.length / 2 + (_unref(subMenuOffsetsComp)['submenu_' + i] || 0), "px + min(0px, calc(50% - ").concat(itemWidth * item.submenu.length / 2 - (_unref(subMenuOffsetsComp)['submenu_' + i] || 0), "px))))"),
                        'margin-right': "max(0px, calc(50% - ".concat(itemWidth * item.submenu.length / 2 - (_unref(subMenuOffsetsComp)['submenu_' + i] || 0), "px - min(opx, calc(50% - ").concat(itemWidth * item.submenu.length / 2 + (_unref(subMenuOffsetsComp)['submenu_' + i] || 0), "px)))")
                      })
                    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.submenu, function (subitem, j) {
                      return _openBlock(), _createBlock(ProductIcon, {
                        key: j,
                        class: "fullheight",
                        router_link: buildDestinationObj(item, subitem),
                        external_link: subitem.externalLink,
                        style: _normalizeStyle([{
                          "display": "inline-block"
                        }, "flex: 0 0 ".concat(itemWidth, "px; width: ").concat(itemWidth, "px")]),
                        name: subitem.navMenuShorthand || subitem.name,
                        icon_link: subitem.icon_link,
                        icon_letter: subitem.icon,
                        active: "",
                        menuItem: "",
                        onClick: function onClick() {
                          handleSubmenuClick(subitem, item);
                          clearDropdown();
                        }
                      }, null, 8, ["router_link", "external_link", "style", "name", "icon_link", "icon_letter", "onClick"]);
                    }), 128))], 4)], 10, _hoisted_8), _createVNode(_Transition, {
                      appear: "",
                      name: "fast-fade-in-place"
                    }, {
                      default: _withCtx(function () {
                        return [_withDirectives(_createElementVNode("div", {
                          class: "arrow",
                          style: {
                            "left": "0px"
                          },
                          onClick: _cache[0] || (_cache[0] = function () {
                            return scrollSubmenu(-1 * (itemWidth * 3));
                          })
                        }, [_createVNode(_component_el_icon, {
                          size: 32
                        }, {
                          default: _withCtx(function () {
                            return [_createVNode(_unref(ArrowLeftBold))];
                          }),
                          _: 1
                        })], 512), [[_vShow, _unref(showLeftArrow)]])];
                      }),
                      _: 1
                    }), _createVNode(_Transition, {
                      apepar: "",
                      name: "fast-fade-in-place"
                    }, {
                      default: _withCtx(function () {
                        return [_withDirectives(_createElementVNode("div", {
                          class: "arrow",
                          style: {
                            "right": "0px"
                          },
                          onClick: _cache[1] || (_cache[1] = function () {
                            return scrollSubmenu(itemWidth * 3);
                          })
                        }, [_createVNode(_component_el_icon, {
                          size: 32
                        }, {
                          default: _withCtx(function () {
                            return [_createVNode(_unref(ArrowRightBold))];
                          }),
                          _: 1
                        })], 512), [[_vShow, _unref(showRightArrow)]])];
                      }),
                      _: 1
                    })])];
                  }),
                  _: 2
                }, 1536), [[_vShow, _unref(currMenuThrottled) === "submenu_".concat(i)]])];
              }),
              _: 2
            }, 1024)) : _createCommentVNode("", true)]);
          }), 128))];
        }),
        _: 1
      }, 512);
    };
  }
};