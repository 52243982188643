import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-25dc80a0"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["src"];
var _hoisted_2 = {
  justify: "start",
  class: "flex-row quote content"
};
var _hoisted_3 = {
  class: "font-color-white"
};
var _hoisted_4 = {
  justify: "start",
  class: "flex-row author content"
};
var _hoisted_5 = {
  notranslate: ""
};
var _hoisted_6 = {
  key: 0,
  justify: "start",
  class: "flex-row credits content"
};
var _hoisted_7 = {
  notranslate: ""
};
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var testimonial = ref(null);
    var router = useRouter();

    var credits = function credits() {
      if (Array.isArray(props.data.meta.testimonial_credits) && props.data.meta.testimonial_credits.length) {
        return props.data.meta.testimonial_credits[0];
      }

      return null;
    };

    onMounted(function () {
      router.registerCSSLazyObserve(testimonial.value.$el);
    });
    return function (_ctx, _cache) {
      var _component_el_col = _resolveComponent("el-col");

      var _component_el_row = _resolveComponent("el-row");

      return _openBlock(), _createBlock(_component_el_row, {
        ref_key: "testimonial",
        ref: testimonial,
        justify: "center",
        class: "testimonial-slide"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_col, {
            class: "fullheight testimonial-left_content"
          }, {
            default: _withCtx(function () {
              return [_createElementVNode("img", {
                class: "testimonial-photo",
                src: __props.data.photo_m[0],
                alt: "Testimonial Photo"
              }, null, 8, _hoisted_1)];
            }),
            _: 1
          }), _createVNode(_component_el_col, {
            class: "testimonial-right_content"
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_el_row, {
                align: "middle",
                justify: "center",
                class: "fullheight"
              }, {
                default: _withCtx(function () {
                  return [_createVNode(_component_el_col, null, {
                    default: _withCtx(function () {
                      return [_createElementVNode("div", _hoisted_2, [_createElementVNode("p", _hoisted_3, "\"" + _toDisplayString(__props.data.meta.testimonial_quote[0]) + "\"", 1)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("p", _hoisted_5, "- " + _toDisplayString(__props.data.meta.testimonial_author[0]), 1)]), credits() ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("p", _hoisted_7, _toDisplayString(credits()), 1)])) : _createCommentVNode("", true)];
                    }),
                    _: 1
                  })];
                }),
                _: 1
              })];
            }),
            _: 1
          })];
        }),
        _: 1
      }, 512);
    };
  }
};