import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { inject, computed } from 'vue';
import WpElButton from '@/components/WpComponents/WpElButton.vue';
export default {
  setup: function setup(__props) {
    var user = inject('user');
    var buttonData = computed(function () {
      var _user$summary, _user$summary2;

      var userSignedIn = user && user.id;
      var buttonObject = {
        visible: true,
        text: 'FREE TRIAL'
      };

      if (!userSignedIn) {
        return buttonObject;
      }

      var isActiveSubscriber = user === null || user === void 0 ? void 0 : (_user$summary = user.summary) === null || _user$summary === void 0 ? void 0 : _user$summary.isActiveSubscriber;
      var isActiveTrialer = user === null || user === void 0 ? void 0 : (_user$summary2 = user.summary) === null || _user$summary2 === void 0 ? void 0 : _user$summary2.isActiveTrialer;
      var hasAvailableTrial = user === null || user === void 0 ? void 0 : user.hasAvailableTrial;

      if (isActiveSubscriber || isActiveTrialer) {
        buttonObject.visible = false;
      } else if (!hasAvailableTrial) {
        buttonObject.text = 'Subscribe Now';
      }

      return buttonObject;
    });
    return function (_ctx, _cache) {
      return _unref(buttonData).visible ? (_openBlock(), _createBlock(WpElButton, {
        key: 0,
        action: "add_to_cart",
        value: 'auto-tune-unlimited-monthly-14d-free',
        type: "primary",
        class: "large"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_unref(buttonData).text), 1)];
        }),
        _: 1
      })) : _createCommentVNode("", true);
    };
  }
};